import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

function setupEditApp(
  node: ReactDOM.Container,
  props: ComponentProps<typeof DossierEditorApp>
) {
  const DossierEditorApp = createLazyComponent(() => import('./DossierEditorApp'));
  attachRoot(<DossierEditorApp {...props} />, node);
}

window.flatfox.myDossier = { setupEditApp };
