import { getMainBaseUrl } from 'flatfox_common/config';
import { urlWithQuery } from 'flatfox_common/ui/utils';

export const auth = {
  userProfile: '/api/v1/auth/user/',
  userProfilePhoto: '/api/v1/profile-photo/',
  emailCheck: '/api/v1/auth/email-check/',
  otp: '/api/v1/auth/otp/',
  login: '/api/v1/auth/login/',
  signup: '/api/v1/auth/registration/',
  logout: '/api/v1/auth/logout/',

  google: '/api/v1/auth/google/',
  facebook: '/api/v1/auth/facebook/',
};

export const myFlat = '/api/v1/listing/:pk/';
export const myFlatClicks = '/api/v1/listing/:pk/clicks/';
export const myFlatImage = '/api/v1/upload/flat_image/';
export const myFlatImageRotate = '/api/v1/upload/flat_image/:pk/rotate/';
export const myFlatDocument = '/api/v1/upload/flat_document/';

export const label = '/api/v1/label/';
export const labelType = '/api/v1/label-type/';

export const quota = '/api/v1/quota/';

export const project = '/api/v1/project/:pk/';

export const upload = '/api/v1/upload/';

export const template = '/api/v1/template/';
export const templateRender = '/api/v1/template/:pk/render/';

export const dossier = '/api/v1/dossier/:pk/';
export const dossierImage = '/api/v1/upload/dossier_image/';
export const dossierDocument = '/api/v1/upload/dossier_document/';

export const transferExport = '/api/v1/export/:pk/';
export const transferExporter = '/api/v1/exporter/:pk/';
export const transferExporterService = '/api/v1/exporter-service/:pk/';

export const movingServicesInquiry = '/api/v1/moving-services/inquiry/';
export const movingServicesInfo = '/api/v1/moving-services/info/';

export const quicklineServicesInquiry = '/api/v1/quickline-services/inquiry/';

export const ad = '/api/v1/publi/ad/:pk/';
export const adLead = '/api/v1/publi/lead/';

export const creditCheckPassportPhoto = '/api/v1/upload/passport_photo/';
export const creditCheckOrder = '/api/v1/credit-check-order/';

export const insuranceRentDepositOffer = '/api/v1/insurance/rent-deposit/:pk/';
export const insuranceRentDepositOfferSend = '/api/v1/insurance/rent-deposit/:pk/send/';
export const insuranceRentDepositOfferConvert =
  '/api/v1/insurance/rent-deposit/:pk/convert/';
export const insurancePrivateLiabilityOrder =
  '/api/v1/insurance/private-liability/order/';
export const insuranceMovingAllRiskOrder = '/api/v1/insurance/moving-all-risk/order/';
export const insuranceHouseholdContentsOrder =
  '/api/v1/insurance/household-contents/order/';

export const taxCalculator = '/api/v1/tax-calculator/';

export const publicListing = '/api/v1/public-listing/:pk/';
export const likeListing = '/api/v1/public-listing/:pk/like/';
export const dislikeListing = '/api/v1/public-listing/:pk/dislike/';
export const resetlikeListing = '/api/v1/public-listing/:pk/resetlike/';
export const similarListings = '/api/v1/public-listing/:pk/similar/';

export const pin = '/api/v1/pin/:pk/';

export const flatLike = '/api/v1/listing-like/:pk/';
export const shareable = '/api/v1/shareable/:pk/';
export const shareableUnfollow = '/api/v1/shareable/:pk/unfollow/';

export const application = '/api/v1/application/:pk/';
export const applicationSubmit = '/api/v1/application/submit/';
export const applicationPush = '/api/v1/application/:pk/push/';
export const applicationCreateChecklist = '/api/v1/application/:pk/create-checklist/';
export const applicationTransfer = '/api/v1/application/:applicationPk/transfer/';
export const applicationDocuments =
  '/api/v1/application/:applicationPk/documents/:docPk/';
export const applicationMergeFields = '/api/v1/application/:pk/merge-fields/';
export const applicationRejectMany = '/api/v1/application/reject-many/';
export const agencyApplicationFlyer = '/api/v1/agency-application-flyer/:branchPk/';

export const directSubmit = '/api/v1/direct-submit/';

export const securityDeposit = '/api/v1/security-deposit/security-deposit-request/:pk/';

export const signatureProcess = '/api/v1/signature-process/';
export const mobilePhoneNumberValidation = '/api/v1/mobile-phone-number-validation/';

export const rentalContract = '/api/v1/rental-contract/:pk/';

export const leaseTermination = '/api/v1/lease-termination/:pk/';
export const publicLeaseTermination = '/api/v1/public-lease-termination/';
export const publicB2CLeaseTermination = '/api/v1/public-b2c-lease-termination/';
export const leasePreListing = '/api/v1/lease-pre-listing/';

export const promotion = '/api/v1/promotion/:pk/';
export const promotionPreview = '/api/v1/promotion/preview/';

export const checklist = '/api/v1/checklist/checklist/:pk/';
export const checklistClose = '/api/v1/checklist/checklist/:pk/close/';
export const checklistReopen = '/api/v1/checklist/checklist/:pk/reopen/';
export const checklistComment = '/api/v1/checklist/comment/';
export const checklistIncome = '/api/v1/checklist/income/:pk/';
export const checklistCreditRisk = '/api/v1/checklist/credit-risk/:pk/';
export const checklistEmployerReference = '/api/v1/checklist/employer-reference/:pk/';
export const employerRefSendExternal =
  '/api/v1/checklist/employer-reference/:pk/send-external/';
export const employerRefApplyExternal =
  '/api/v1/checklist/employer-reference/:pk/apply-external/';
export const checklistLandlordReference = '/api/v1/checklist/landlord-reference/:pk/';
export const landlordRefSendExternal =
  '/api/v1/checklist/landlord-reference/:pk/send-external/';
export const landlordRefApplyExternal =
  '/api/v1/checklist/landlord-reference/:pk/apply-external/';

export const schedule = '/api/v1/schedule/:pk/';
export const publicSchedule = '/api/v1/public-schedule/:pk/';
export const scheduleEvent = '/api/v1/schedule-event/:pk/';
export const attendance = '/api/v1/attendance/:pk/';

export const marketingService = '/api/v1/marketing-service/';

export const messenger = {
  threads: '/api/v1/thread/:pk/',
  threadsTouched: '/api/v1/thread/touched/',
  message: '/api/v1/message/',
  messages: '/api/v1/message/:pk/',
  messagesMany: '/api/v1/message/create-many/',
  views: '/api/v1/view/',
  viewsMany: '/api/v1/view/create-many/',
  hiddenStateCreateMany: '/api/v1/hidden-state/create-many/',
  hiddenStateDeleteMany: '/api/v1/hidden-state/delete-many/',
  dateProposal: '/api/v1/date-proposal/',
  dateProposals: '/api/v1/date-proposal/:pk/',
  attachments: '/api/v1/attachment/',
  unreadStates: '/api/v1/unread-state/',
};

export const subscription = '/api/v1/subscription/:pk/';

// Billing
export const purchase = '/api/v1/purchase/:pk/';
export const paymentIntent = '/api/v1/payment-intent/';

// Configuration
export const config = '/api/v1/config/';
export const formConfig = '/api/v1/config/form/:pk/';
export const messageConfig = '/api/v1/config/message/:pk/';
export const messageConfigForListing = '/api/v1/config/message/for-listing/:pk/';
export const messageConfigForTicket = '/api/v1/config/message/for-ticket/:pk/';
export const trustedSitesConfig = '/api/v1/config/trusted-sites/';
export const snippets = '/api/v1/snippet/:pk/';

export const phoneNumber = '/api/v1/phone-number/:pk/';
export const branch = '/api/v1/branch/';
export const organization = '/api/v1/organization/';
export const employee = '/api/v1/employee/';
export const member = '/api/v1/member/';
export const country = '/api/v1/country/';

export const crm = {
  match: '/api/v1/crm/match/:pk/',
  sendMatches: '/api/v1/crm/match/send-many/',
  deleteMatches: '/api/v1/crm/match/delete-many/',
  contact: '/api/v1/crm/contact/:pk/',
  invite: '/api/v1/crm/contact/invite/',
  searchProfile: '/api/v1/crm/search-profile/:pk/',
};

export const organizer = {
  note: '/api/v1/organizer/note/:pk/',
  noteGroupBy: '/api/v1/organizer/note/groupby/:groupby_fields/',
};

export const publicAgencyAutocomplete = '/api/v1/public-agency/:pk/';
export const publicAutocompleteHouse = '/api/v1/public-autocomplete-house/:pk/';
export const autocompleteHouse = '/api/v1/autocomplete-house/:pk/';
export const autocompleteMember = '/api/v1/autocomplete-member/:pk/';

export const letterPreview = '/api/v1/letter-preview/';

export const applicationFlyerPreview = '/api/v1/application-flyer-preview/';

export const publicInquiry = '/api/v1/public-inquiry/';
export const ticket = '/api/v1/ticket/:pk/';
export const forwardTicket = '/api/v1/ticket/:pk/forward/';

export const ticketComment = '/api/v1/ticket-comment/:pk/';

export const workOrder = '/api/v1/work-order/:pk/';
export const workOrderPush = '/api/v1/work-order/:pk/push/';
export const workOrderNumber = '/api/v1/ticket/:pk/next-work-order-number/';

export const quote = '/api/v1/quote/:pk/';
export const quoteAccept = '/api/v1/quote/:pk/accept/';
export const quoteReject = '/api/v1/quote/:pk/reject/';
export const quotesMany = '/api/v1/quote/create-many/';
export const uploadQuoteOffer = '/api/v1/quote/:pk/upload-offer/';

export const agencyTicketFlyer = '/api/v1/agency-ticket-flyer/:branchPk/';

export const account = '/api/v1/account/:pk/';
export const property = '/api/v1/property/:pk/';
export const costCenter = '/api/v1/cost-center/:pk/';
export const contactRequest = '/api/v1/contact-request/';
export const autocompleteContact = '/api/v1/autocomplete-contact/:pk/';
export const autocompleteObject = '/api/v1/autocomplete-object/:pk/';

export const selection = '/api/v1/selection/:pk/';

export function list(url: string, query = {}): string {
  const path = urlWithQuery(url.replace(':pk/', ''), query);
  const mainBaseUrl = getMainBaseUrl() || '';
  return `${mainBaseUrl}${path}`;
}

export function detail(
  url: string,
  pk: string | number,
  query: Record<string, unknown> = {}
): string {
  const path = urlWithQuery(url.replace(':pk', `${pk}`), query);
  const mainBaseUrl = getMainBaseUrl() || '';
  return `${mainBaseUrl}${path}`;
}
